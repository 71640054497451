@import "../styles/base.scss";

.page-fingerprint-game {
  background-color: #fafaf8;
  // background-color: #e8746f;
  min-height: calc(100vh - 65px);
  padding-top: 65px;

  &__header {
    background-color: #fafaf8;
    height: 65px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    color: whitesmoke;
    border-bottom: 1px solid #e4e5e1;
  }

  &__header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    position: relative;
    justify-content: space-between;
    max-width: 1600px;
    position: relative;
    // width: 100%;
    // box-sizing: border-box;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;

    @include desktop {
      padding: 0;
    }
    &::before {
      content: "match";
      font-size: 12px;
      font-weight: 600;
      color: #f14404;
      position: absolute;
      left: 134px;
      bottom: 10px;
      z-index: 1000000;

      @include desktop {
        left: 136px;
      }
    }
  }

  &__card-front-icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fafaf8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  &__card-back-tags {
    // border: solid 1px magenta;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__header-nooks-logo {
    height: 28px;
    width: auto;

    @include desktop {
      height: 30px;
    }
  }

  // &__back {
  //   font-weight: 600;
  //   overflow: hidden;
  //   color: whitesmoke;
  //   font-size: 16px;

  //   @include desktop {
  //     font-size: 18px;
  //   }
  // }

  &__score {
    // width: 120px;
    // height: 70px;
    // position: absolute;
    right: 0;
    bottom: -30px;
    // background-color: #ea7657;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // border: dashed 2px white;
    height: 70%;
  }

  &__score-label {
    font-weight: 600;
    text-align: center;
    color: #f14404;
    font-size: 14px;
  }

  &__score-value {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    color: #141415;
  }

  &__main-container {
    // border: solid 1px magenta;
    display: flex;
    flex-direction: column;
    padding-bottom: 65px;
  }

  &__actions {
    // background-color: #5d2aca;
    // border-bottom-left-radius: 20px;
    // border-bottom-right-radius: 20px;

    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    //   rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  // &__cards {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 20px;
  //   margin-top: 100px;

  //   // border: dashed 1px yellowgreen;

  //   @include desktop {
  //     // flex-direction: row;
  //     gap: 40px;
  //     flex-wrap: wrap;
  //   }
  // }

  &__cards {
    // border: dotted 1px magenta;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin: 20px 20px 0 20px;
    flex-wrap: wrap;
    max-width: 1600px;

    @include desktop {
      flex-direction: row;
    }
  }

  // &__requirements {
  //   border: dotted 1px red;
  //   flex: 1;
  //   display: flex;
  //   flex-direction: column;
  //   padding-right: 5px;
  //   align-items: center;
  //   flex-shrink: 0;

  //   @include desktop {
  //     padding-right: 20px;
  //   }
  // }

  // &__matches {
  //   border: dotted 1px green;
  //   flex: 1;
  //   display: flex;
  //   flex-direction: column;
  //   padding-left: 5px;
  //   align-items: center;
  //   flex-shrink: 0;

  //   @include desktop {
  //     padding-left: 20px;
  //   }
  // }

  &__card {
    // background-color: #b295f1;
    // border-radius: 6px;
    border: none;
    height: 200px;

    width: 100%;
    perspective: 1000px;
    flex-shrink: 0;
    opacity: 0;
    margin: 0 20px 20px 0;
    animation: show-card 1s forwards;

    // flex: 1;

    // &--initial-animation {
    //   opacity: 0;
    //   animation: show-card 1s forwards;
    // }

    &--shake {
      opacity: 1;
      animation-name: tilt-n-move-shaking;
      animation-iteration-count: 2;
      animation-duration: 0.4s;
      // animation-delay: 0 !important;

      &:nth-child(even) {
        animation-delay: 0.2s !important;
      }

      &:nth-child(odd) {
        animation-delay: 0s !important;
      }
    }

    @include desktop {
      // height: 300px;
      height: 200px;
      max-width: 300px;

      &:hover {
        transition: transform 0.3s;
        // transform: scale(1.02);
      }
    }

    &--flip {
      .page-fingerprint-game__flip-card-inner {
        transform: rotateY(180deg);
      }
    }

    // & + & {
    //   margin-top: 20px;

    //   @include desktop {
    //     margin-top: 60px;
    //   }
    // }
  }

  &__card-front {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    cursor: pointer !important;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    border: none;
    padding: 20px;
    box-sizing: border-box;
    // border-radius: 6px;

    border: 1px solid #e4e5e1;
    border-radius: 10px;
  }

  &__card-front-type {
    // border: solid 1px #5d2aca;
    border-radius: 10px;
    padding: 2px 10px;
    font-size: 12px;
    background: linear-gradient(
      94.48deg,
      rgba(245, 97, 75, 0.2),
      rgba(245, 97, 75, 0.2) 0.01%,
      rgba(250, 117, 69, 0.2)
    );
    color: #ff5e24;
    // width: 100%;
    text-align: left;
    align-self: center;
  }

  &__card-back {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    // background-color: #ddcdff;
    color: black;
    height: 100%;
    position: absolute;
    transform: rotateY(180deg);
    width: 100%;
    // overflow-y: scroll;

    display: flex;
    flex-direction: column;
    // border-radius: 6px;
    // z-index: 6;
    padding: 14px 18px 20px 18px;
    box-sizing: border-box;

    border: 1px solid #e4e5e1;
    // #ff5e24
    border-radius: 10px;
  }

  &__requirement-title {
    font-size: 15px;
    font-weight: bold;
    color: #141415;
    margin-top: 10px;
    // text-align: left;
  }

  // &__requirement-number {
  //   background-color: #b295f1;
  //   width: 60px;
  //   height: 60px;
  //   border-radius: 50%;
  //   color: whitesmoke;
  //   opacity: 0.8;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 28px;
  //   border: 2px solid #c9b8ee;
  //   font-weight: 600;

  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;

  //   // background-image: url(../images/nooks-logo-only.png);
  //   // background-repeat: no-repeat;
  //   // background-size: 60%;
  //   // background-position: center;

  //   &--skill {
  //     background-image: unset;
  //   }

  //   @include desktop {
  //     width: 100px;
  //     font-size: 40px;
  //     height: 100px;
  //   }
  // }

  &__oops-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    line-height: 1.4;
    font-size: 14px;

    @include desktop {
      font-size: 16px;
    }
  }

  &__modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.7);
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__modal-panel {
    // background-color: white;
    box-sizing: border-box;
    border-radius: 20px;
    width: 90%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    animation: blowUpModal 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    opacity: 0;
    z-index: 5;
    // background-color: #16172e;
    color: white;

    // &--light {
    //   background-color: white;
    //   color: #16172e;
    // }

    background-color: #ffffff;
    color: #141415;

    @include desktop {
      max-width: 600px;
    }

    &--full {
      height: calc(100% - 40px);

      @include desktop {
        height: auto;
        max-height: calc(100% - 40px);
      }
    }
  }

  &__modal-button {
    // background-color: #e8746f;
    // color: white;
    // width: 100%;
    // padding: 12px 20px;
    padding: 0 15px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    display: block;
    cursor: pointer;
    outline: none;
    flex-shrink: 0;
    height: 38px;

    background-color: #f35b22;
    border-color: #be400f;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2),
      0 1px 2px 0 rgba(24, 25, 22, 0.06);
    color: white;

    transition-duration: 250ms;
    transition-property: background-color, border, color;
    transition-timing-function: ease;
    white-space: nowrap;

    &:hover {
      background-color: #d14200;
      border-color: #d14200;
    }

    &--secondary {
      background-color: #f8f8f6;
      border: 0.125rem solid #f35b22;
      box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2),
        0 1px 2px 0 rgba(24, 25, 22, 0.06);
      color: #f35b22;

      &:hover {
        background-color: #f8f8f6;
        border-color: #f35b22;
      }
    }

    // &:hover,
    // &:focus {
    //   @include desktop {
    //     background-color: darken(#f35b22, 8);
    //   }
    // }

    // &:active {
    //   @include desktop {
    //     background-color: darken(#f35b22, 16);
    //   }
    // }

    @include desktop {
      // max-width: 200px;
      align-self: center;
    }
  }

  &__action-button {
    // background-color: #e8746f;
    // color: white;
    // width: 100%;
    // padding: 12px 20px;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    font-size: 13px;
    display: block;
    cursor: pointer;
    outline: none;
    // flex-shrink: 0;
    text-decoration: none;
    // margin: 10px;
    -webkit-tap-highlight-color: transparent;

    @include desktop {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      @include desktop {
        background-color: darken(#cc0eab, 8);
      }
    }

    &:active {
      @include desktop {
        background-color: darken(#cc0eab, 16);
      }
    }

    // @include desktop {
    //   max-width: 200px;
    //   align-self: center;
    // }
  }

  &__modal-titlebar {
    // background-color: #7740ee;
    // background-color: lighten($color: #16172e, $amount: 10);
    // background-color: #7740ee;
    // color: white;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background-color: #fafaf8;
    color: #141415;
  }

  &__modal-content {
    padding: 30px 30px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    line-height: 1.3;
    // color: white;

    // border: dashed 2px greenyellow;

    height: 100%;
    scroll-behavior: smooth;

    overflow-y: auto;
  }

  &__modal-text {
    // display: flex;
    // flex-direction: column;
    margin-top: 16px;
  }

  &__modal-footer {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__welcome-modal-wrappwer {
    // background-color: #16172e;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__welcome-modal-logo {
    height: 38px;
    margin: 20px 0 32px;
    @include desktop {
      // height: 52px;
    }
  }

  &__match-modal-lottie {
    // border: dashed 1px red;
    width: 100%;
    max-width: 400px;
    // align-self: center;
    margin: 0 auto;
  }

  &__match-modal-points-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    // width: 100%;
    margin-left: 5px;
  }

  &__match-modal-points-badge {
    // height: 16px;
    text-align: center;

    background-color: #ceeece;
    // border: solid 1px green;
    border-radius: 10px;
    font-size: 12px;
    padding: 2px 12px;

    &--partial {
      background-color: #ffffe7;
      border: solid 1px #e8cb09;
    }
  }

  &__req-back-text {
    position: relative;
    flex: 1;
    margin-top: 8px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.4;
    // margin-bottom: 8px;

    @include desktop {
      font-size: 16px;
    }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: linear-gradient(
    //     to bottom,
    //     rgba(#ddcdff, 0) 0%,
    //     rgba(#ddcdff, 0) 70%,
    //     rgba(#ddcdff, 00) 80%,
    //     rgba(#ddcdff, 1) 90%
    //   );
    // }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tip {
    background-color: #e1e1d0;
    border-radius: 8px;
    padding: 10px 10px;
    margin-top: 20px;
    display: inline-block;
    align-self: center;
    justify-self: center;
    margin: 20px 20px 0 20px;

    // opacity: 0;
    // animation: show-card 1s 1.3s forwards;

    // animation: tip-animation 0.8s infinite alternate ease-in;
    // animation-direction: reverse;

    @include desktop {
      padding: 10px 20px;
    }
  }

  &__skill {
    margin-top: 20px;
  }

  &__skill-wrapper {
    margin-top: 10px;
    width: 100%;

    a {
      text-decoration: none;
      word-break: break-word;
    }
  }

  &__skill-item {
    width: 100%;
    margin-top: 20px;
  }

  &__skill-links {
    width: 100%;
  }

  &__skill-details {
    margin-top: 20px;
  }

  &__skill-category {
    font-size: 12px;
    background-color: #d5e7ff;
    padding: 2px 8px;
    display: inline-block;
    color: black;
    border-radius: 6px;

    &--professional {
      background-color: #ffd891;
    }

    &--personal {
      background-color: #ffd5d5;
    }
  }

  &__skill-title {
    font-weight: 600;
    margin-top: 2px;
  }

  &__skill-links {
    margin-top: 10px;
    word-break: break-all;
  }

  &__skill-details {
    line-height: 1.4;
    font-size: 14px;

    @include desktop {
      font-size: 16px;
    }
  }

  &__footer {
    background-color: black;
    color: whitesmoke;
    padding: 65px 0;

    font-size: 14px;

    @include desktop {
      font-size: 16px;
    }
  }

  &__footer-link {
    color: white;
  }

  &__flip-card-inner {
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    // border: solid 6px #b295f1;
    // border-radius: 6px;
    // cursor: pointer;
    background: transparent;
    color: white;
    height: 100%;

    // margin: 0;
    // padding: 0;
    // border: none;

    @include desktop {
      // border: solid 12px #b295f1;
    }
  }
}

// .flip-card:hover .flip-card-inner {
//   transform: rotateY(180deg);
// }

@keyframes tilt-n-move-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1.5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-1.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes show-card {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tip-animation {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}
