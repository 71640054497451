@import "../styles/base.scss";

.nooks-game {
  background-color: #7740ee;
  height: 400px;
  color: white;
  margin-top: 100px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    line-height: 1.4;
    font-size: 20px;
  }

  &__button {
    background-color: #cc0eab;
    color: white;
    // width: 100%;
    padding: 12px 20px;
    // border: none;
    border: solid 2px whitesmoke;
    border-radius: 20px;
    font-weight: 600;
    font-size: 13px;
    display: block;
    cursor: pointer;
    outline: none;
    // flex-shrink: 0;
    text-decoration: none;
    margin: 10px;
    margin-top: 40px;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.3s;

    @include desktop {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      @include desktop {
        background-color: darken(#cc0eab, 8);
      }
    }

    &:active {
      @include desktop {
        background-color: darken(#cc0eab, 16);
      }
    }

    &:hover {
      transform: scale(1.05);
    }

    // @include desktop {
    //   max-width: 200px;
    //   align-self: center;
    // }
  }
}
