@import "../styles/base.scss";

.projects {
  $block: &;

  display: flex;
  flex-direction: column;
  padding: 10px 0 70px;

  @include desktop {
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__card {
    $card: &;

    flex-shrink: 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    // height: 100%;
    // box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    //   rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // justify-content: stretch;
    // border: dashed 2px magenta;

    & + & {
      margin-top: 10px;

      @include desktop {
        margin-top: 0;
      }
    }

    @include desktop {
      margin-top: 0;
      margin-left: 15px;
      width: calc(50% - 15px);

      &:nth-child(odd) {
        margin-right: 15px;
        margin-left: 0;
      }

      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }

    //

    &-cover {
      // border: solid 1px magenta;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-repeat: no-repeat;
      height: 50px;
      background-color: white;
      background-position: center;
      transition: background-size 0.3s;
      // position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 10px;
      // background-size: auto 40%;

      // filter: grayscale(1);

      @include desktop {
        // height: 50px;

        // #{$card}:hover & {
        //   // filter: none;
        //   background-size: auto 240% !important;
        // }
      }
    }
  }

  &__cover-wrapper {
    position: sticky;
    top: 65px;
    padding-top: 20px;
    background-color: #f9f9f9;
  }

  &__link {
    background-color: rgba(black, 0.2);
    width: 30px;
    height: 30px;
    align-self: center;
    // justify-self: flex-end;
    cursor: pointer;
    border-radius: 50%;
    // position: absolute;
    // right: 15px;
    // bottom: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link-icon {
    width: 40%;
    height: auto;
  }

  &__project-title {
    // background-color: #f6f6f6;
    padding: 24px 10px 8px;
    font-weight: bold;
    text-align: center;
    font-size: 15px;

    display: flex;
    flex-direction: column;
  }

  &__title-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__project-type {
    color: red;
    display: inline;
    font-size: 14px;
  }

  &__project-label {
    font-size: 12px;
    font-weight: bold;
    color: rgba(black, 1);
    // letter-spacing: 1px;
    margin-bottom: 8px;
  }

  &__project-date {
    font-size: 13px;
    font-weight: bold;
    color: rgba(black, 1);
    // letter-spacing: 1px;
    // margin-bottom: 4px;
    margin-top: 8px;
    align-self: flex-end;
  }

  &__project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__project-content {
    // border: solid 1px #f6f6f6;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    // height: 100%;

    margin-bottom: -10px;
    background-color: #fff;
    padding: 20px;
    flex: 1;
  }

  &__pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    font-weight: normal;
  }

  &__pill {
    // background: #ffe7c9;
    // border: solid 1px #ffbb3f;
    border-radius: 4px;
    padding: 3px 14px;
    display: inline-block;
    font-size: 13px;
    margin-top: 4px;
    margin-right: 4px;
    font-weight: bold;
    // opacity: 0.5;
    color: black; // &--role {
    //   background: #e5ffe7;
    //   border: solid 1px #96d769;
    // }
    // &--date {
    //   background: #e9e3ff;
    //   border: solid 1px #d0b9fa;
    // }
    // &:first-of-type {
    //   background: #e5ffe7;
    //   border: solid 1px #96d769;
    // }
    @include desktop {
      // font-size: 15px;
    }

    & + & {
    }
  }

  &__paragraphs {
    margin: 0;
    // padding: 0;
    padding-left: 16px;
  }
}
