@import "../styles/base.scss";

.page-index {
  // margin-top: 65px;
  background-color: #f9f9f9;

  li {
    + li {
      margin-top: 16px;
    }
  }

  &__tltr {
    // background-color: #f0f0f0;
    background-color: #e0e0e0;
    padding: 6px 10px;
    // border-radius: 6px;
    display: inline-block;
    margin-bottom: 10px;
    line-height: 1.6;
    border-radius: 5px;
    margin-top: 6px;
  }

  &__header {
    // background-color: black;
    // background-color: red;
    height: 65px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 3;
    // opacity: 0;
    // transition: opacity 0.3s;

    &--visible {
      // z-index: 3;
      // opacity: 1;
    }
  }

  &__header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }

  &__header-avatar-img {
    border-radius: 50%;
    width: 36px;
    opacity: 0;
    transition: opacity 0.3s;

    @include desktop {
      width: 40px;
    }

    &--visible {
      opacity: 1;
    }
  }

  &__header-highlight {
    font-weight: 600;
    overflow: hidden;
    color: whitesmoke;
    font-size: 16px;
    // display: none;
    opacity: 0;
    transition: opacity 0.3s;

    @include desktop {
      font-size: 18px;
    }

    &--visible {
      // display: unset;
      opacity: 1;
      z-index: 3;
    }
  }

  &__hero {
    background: black;
    z-index: 2;
    // position: relative;
    // padding: 200px 0;
    height: 462px;
    position: sticky;
    position: -webkit-sticky;
    top: -397px;
    // top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      // background: red;
    }
  }

  &__hero-headline {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    color: whitesmoke;
    line-height: 1.4;

    @include desktop {
      font-size: 34px;
    }
  }

  &__main {
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
  }

  &__about {
    align-self: center;
    text-align: center;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    @include desktop {
      font-size: 16px;
    }
  }

  &__about-text {
    margin-top: 20px;
    line-height: 1.3;

    @include desktop {
      max-width: 490px;
    }
  }

  &__avatar-img {
    border-radius: 50%;
    width: 100px;

    @include desktop {
      width: 200px;
    }
  }

  &__main-block {
    & + & {
      margin-top: 40px;
    }
  }

  &__company-name {
    background-color: #eaf4ff;
    padding: 8px 4px;
    // display: inline-block;
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
    margin-bottom: 4px;

    @include desktop {
      top: 117px;
    }

    a {
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__company-period {
    color: rgba(black, 0.8);
  }

  &__highlights {
    margin-top: 16px;
  }

  &__footer {
    background-color: black;
    height: 200px;
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: whitesmoke;
      display: block;
    }
  }

  &__skill-pill {
    background: #e5ffe7;
    border: solid 1px #96d769;
    border-radius: 30px;
    padding: 2px 14px;
    display: inline-block;
    font-size: 14px;
    margin-top: 4px;
    margin-right: 4px;

    @include desktop {
      // font-size: 15px;
    }

    & + & {
    }
  }

  &__bio {
    @include desktop {
      margin-bottom: 60px;
    }
  }
}
