@import-normalize;
@import "./styles/base.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600&display=swap");

* {
  font-family: "Source Code Pro", monospace;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Code Pro", monospace;
  // background-color: black;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.container-game {
  // border: dashed 1px magenta;
  width: 100%;
  margin: 0 auto;
  // box-sizing: border-box;
  max-width: 1640px;
}

.mobile-only {
  display: unset;

  @include desktop {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include desktop {
    display: unset;
  }
}

h1 {
  font-size: 22px;
  font-weight: 600;
  // position: sticky;
  // position: -webkit-sticky;
  // top: 65px;
  // background-color: white;
  background-color: black;
  color: whitesmoke;
  margin: 0;
  padding: 10px 6px;
  // border-bottom: solid 1px #f4f4f4;
  // margin-bottom: 14px;
  z-index: 1;
  border-radius: 5px;

  @include desktop {
    padding: 20px 12px;
    font-size: 24px;
  }
}

p {
  line-height: 1.3;
  font-size: 15px;

  @include desktop {
    font-size: 16px;
  }
}

a {
  color: black;
}
